import { createApp } from 'vue';
import App from './App.vue';
import { Web3ModalProvider } from '@web3modal/wagmi/vue';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/vue'

const app = createApp(App);
import { mainnet, arbitrum } from '@wagmi/core/chains'

// 1. Get projectId
const projectId = 'ef50e54f0e6e9eb5dc3156a0bbd8c924'

// 2. Create wagmiConfig
const metadata = {
    name: 'Metatope Signer',
    description: 'Example sign app for metatope',
    url: 'https://mtsign.cpi.dev',
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [mainnet, arbitrum]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })
app.use(Web3ModalProvider, wagmiConfig);
createWeb3Modal({ wagmiConfig, projectId, chains })

app.mount('#app');
