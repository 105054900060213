<template>
  <div>
    <button @click="connectWallet">Connect Wallet</button>
    <button @click="signNonce" :disabled="!isConnected">Sign Nonce</button>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useWeb3Modal } from '@web3modal/wagmi/vue';

const { open } = useWeb3Modal();
const isConnected = ref(false);
let url = '1';
import { signMessage } from '@wagmi/core'
import { getAccount } from '@wagmi/core'

const connectWallet = async () => {
  try {
    await open();
    isConnected.value = true;
  } catch (error) {
    console.error('Failed to connect:', error);
  }
};

const signNonce = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const nonce = urlParams.get('nonce');

  const account = getAccount()

  const signature = await signMessage({
    message: nonce
  })

  console.log(account, signature);

  url = `metatope://signresponse/${account.address}?signature=${signature}`;
  window.location.href = url;
};

</script>
