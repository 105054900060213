<template>
  <div id="app">
    <WalletConnectComponent />
  </div>
</template>

<script>
import WalletConnectComponent from "./components/WalletConnectComponent.vue";

export default {
  components: {
    WalletConnectComponent,
  },
};
</script>
